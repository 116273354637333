<template>
  <div class="sz-ace-likelihood">
    <a-modal v-model:visible="visible" title="Likelihood" width="80%" @ok="handleOk">
      <div ref="likelihoodTableWrapper" class="sz-ace-likelihood-wrapper">
        <a-table
          v-if="data.length !== 0"
          :data-source="data"
          :columns="columns"
          :pagination="false"
          size="small"
          :style="{ maxWidth: '1000px' }"
          class="sz-ace-likelihood-table"
          :scroll="{ y: 0 }"
        >
          <template #first-col="{ text }">
            <div :style="{ width: 'max-content' }">
              {{ text }}
            </div>
          </template>
          <template #response="{ text, index }">
            <ProbSelector
              v-if="
                text?.combination && !isAnalytics && isStochastics && !(text?.disabled || !editable)
              "
              :key="index"
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :row-id="text?.rowId"
              :discrete="false"
              :response="text?.response"
              :allow-critical="false"
              :disabled="text?.disabled || !editable"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
            <div
              v-if="text?.combination && !isAnalytics && isStochastics && text?.disabled"
              class="sz-prob-placeholder"
            ></div>
            <Prob
              v-if="text?.combination && !isAnalytics && !isStochastics"
              :key="index"
              :ref="
                (el) => {
                  if (el) {
                    probs[text?.combKey] = el
                  }
                }
              "
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :row-id="text?.rowId"
              :discrete="false"
              :allow-critical="false"
              :response="text?.response"
              :disabled="text?.disabled || !editable"
              :col-index="text?.colIndex"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
            <ProbAgg
              v-if="
                text?.combination && isAnalytics && (!isStochastics || text?.disabled || !editable)
              "
              :ref="
                (el) => {
                  if (el) {
                    probs[text?.combKey] = el
                  }
                }
              "
              :key="index"
              :comb-key="text?.combKey"
              :combination="text?.combination"
              :variable="selectedVariable"
              :row-index="index"
              :row-id="text?.rowId"
              :discrete="false"
              :allow-critical="false"
              :response="text?.response"
              :disabled="text?.disabled || !editable"
              :analytics="analytics"
              :user-map="userMap"
              :anonymous="isAnonymous"
              v-on="{
                [PROB_EVENTS.ON_PROB_CHANGE]: onResponseChange
              }"
            />
          </template>
        </a-table>
      </div>
    </a-modal>
    <mini-likelihood
      v-if="!isStochastics"
      ref="mini"
      :rows="data"
      :columns="columns"
      @click="editLikelihood"
    />
    <div v-if="isStochastics" class="sz-mini-likelihood-stochastic" @click="editLikelihood">
      <span>Edit Likelihood</span>
    </div>
  </div>
</template>

<script lang="ts">
import { isEmpty, isNil } from 'lodash-es'
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  PropType,
  Ref,
  ref,
  watch
} from 'vue'

import { EVENTS as PROB_EVENTS, redistributeProbs } from '@/components/input/common'
import Prob from '@/components/input/Prob.vue'
import ProbAgg from '@/components/input/ProbAgg.vue'
import ProbSelector from '@/components/input/ProbSelector.vue'
import { ResponseUnit } from '@/libs/bayes/CPTResponse'
import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { CPT } from '@/libs/bayes/methods/CPT'
import { Network } from '@/libs/bayes/Network'
import { BEST_LIKELIHOOD, WORST_LIKELIHOOD } from '@/libs/bayes/State'
import { LIKELIHOOD, Variable } from '@/libs/bayes/Variable'
import { getStateStyle } from '@/libs/utils'
import { ResponseSchema } from '@/types'

import MiniLikelihood from './MiniLikelihood.vue'

export const EVENTS = {
  ON_RESPONSES_CHANGE: 'onResponsesChange'
}

export default defineComponent({
  components: {
    Prob,
    ProbAgg,
    ProbSelector,
    MiniLikelihood
  },
  props: {
    network: { type: Network, required: true },
    cpt: { type: Object as PropType<CPT>, required: true },
    selectedVariable: { type: Object as PropType<Variable>, required: true },
    responses: { type: Array as PropType<Array<ResponseSchema>>, default: () => [] },
    analytics: { type: Object, default: undefined },
    depCombKey: { type: String, default: undefined },
    responseMap: { type: Object, required: true },
    validMap: { type: Object, required: true },
    userMap: { type: Object, default: undefined },
    isAnonymous: { type: Boolean },
    editable: { type: Boolean, default: true },
    isStochastics: { type: Boolean, default: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props) {
    const mini = ref<InstanceType<typeof MiniLikelihood>>()
    const visible = ref(false)
    const miniLikelihoodKey = ref(0)
    const isAnalytics = computed(() => !isEmpty(props.analytics))
    const allVarStates = computed(() => props.selectedVariable.getAllStates())
    const probs = ref<Record<string, any>>({})

    const editLikelihood = () => {
      visible.value = !visible.value
    }

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      probs.value = {}
    })
    const columns = computed(() => {
      let stateColumns: any[] = []

      if (allVarStates.value?.length) {
        stateColumns = allVarStates.value.map(({ variable, state }) => {
          const combKey = `${props.depCombKey}-${variable.id}+${state.id}`
          // const combination = node.combination
          //   ? [...node.combination].concat([[variable.id, state.id]])
          //   : []
          return {
            title: state.name,
            key: combKey,
            dataIndex: combKey,
            align: 'center',
            polarity: state.polarity,
            slots: {
              customRender: 'response'
            },
            customHeaderCell: (col: any) => {
              return {
                style: {
                  ...getStateStyle(col.polarity)
                }
              }
            }
          }
        })
      }

      return [
        {
          name: 'name',
          dataIndex: 'name',
          align: 'left',
          fixed: 'left',
          width: 400,
          slots: {
            customRender: 'first-col'
          }
        }
      ].concat(stateColumns)
    })

    const genData = (isBest: boolean) => {
      if (allVarStates.value?.length) {
        return allVarStates.value.reduce((acc: any, { variable, state }, index) => {
          const dataIndex = `${props.depCombKey}-${variable.id}+${state.id}`
          const key =
            `${dataIndex}-${LIKELIHOOD.id}+` + (isBest ? BEST_LIKELIHOOD.id : WORST_LIKELIHOOD.id)
          const response = props.responseMap.get(key) || {}
          if (!isAnalytics.value) {
            if (isNil(response?.response)) {
              response.response = {}
            }
            if (isNil(response.response.value)) {
              if (
                (isBest && index === allVarStates.value?.length - 1) ||
                (!isBest && index === 0)
              ) {
                response.response.value = 100
              } else {
                response.response.value = 0
              }
            }
          } else {
            if (isNil(response?.response)) {
              response.response = {}
            }
            if (isNil(response.response.value)) {
              if (
                (isBest && index === allVarStates.value?.length - 1) ||
                (!isBest && index === 0)
              ) {
                response.response.value = 100
              } else {
                response.response.value = 0
              }
            }
          }
          // Allow overrides
          //
          // const rowId = response.rowId
          // if (isAnalytics.value) {
          //   if (rowId) {
          //     const rowItem = props.analytics?.rowMap.get(rowId)
          //     const responses = rowItem?.responses || []
          //     const values = responses.map((response: ResponseSchema) => response.value)
          //     response.mean = mean(values)
          //   } else {
          //     response.mean = -1
          //   }
          // }
          response.colIndex = index
          response.disabled = index === 0
          acc[dataIndex] = response
          return acc
        }, {})
      }
      return {}
    }

    const genRows = () => {
      return [
        {
          name: 'Best likelihood',
          key: 'best',
          ...genData(true)
        },
        {
          name: 'Worst likelihood',
          key: 'worst',
          ...genData(false)
        }
      ]
    }

    const data: Ref<Array<any>> = ref([])

    const onResponseChange = (
      {
        response,
        combKey,
        valid,
        rowIndex
      }: {
        response: ResponseSchema
        combKey: string
        valid: boolean
        rowIndex: number
      },
      noDist = false
    ) => {
      props.validMap.set(combKey, valid)
      const responseUnit = props.responseMap.get(combKey)
      if (!responseUnit) {
        return
      }
      const rowData = data.value[rowIndex]
      const isDeterministic = props.selectedVariable?.isDeterministic()
      try {
        if (!noDist) {
          redistributeProbs(rowData, columns.value.slice(1), probs.value, combKey, isDeterministic)
        }
      } catch (e) {
        console.log(e)
        // pass
      }
      const comboRow: ResponseUnit = { ...responseUnit }
      comboRow.response = Object.assign({ ...responseUnit.response }, response)
      props.responseMap.set(combKey, comboRow)
    }

    onMounted(() => {
      data.value = genRows()
      mini?.value?.$forceUpdate()
    })

    watch(visible, () => {
      data.value = genRows()
      mini?.value?.$forceUpdate()
    })

    watch(
      () => props.responses,
      () => {
        data.value = genRows()
        mini?.value?.$forceUpdate()
      }
    )

    watch(
      () => props.selectedVariable,
      () => {
        data.value = genRows()
        mini?.value?.$forceUpdate()
      }
    )

    return {
      editLikelihood,
      miniLikelihoodKey,
      PROB_EVENTS,
      VariableRelation,
      columns,
      data,
      mini,
      probs,
      onResponseChange,
      isAnalytics,
      visible,
      handleOk: () => {
        visible.value = false
      }
    }
  }
})
</script>

<style lang="stylus">
@import '../../styles/commons.styl';

.sz-mini-likelihood
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: stretch
  .sz-mini-likelihood-cols, .sz-mini-likelihood-row
    flex: 1 1 auto
    display: flex
    flex-direction: row
    align-items: stretch
  .sz-mini-likelihood-cell
    flex: 1 1 auto

.sz-ace-likelihood-wrapper .sz-ace-likelihood-table td
  position: relative

.sz-ace-likelihood-wrapper .sz-ace-likelihood-table tr
  height: 45px !important

.sz-ace-likelihood,
.sz-ace-likelihood-wrapper .sz-ace-likelihood-table .sz-ace-input-number
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.sz-ace-likelihood button
  height: 42px !important

.sz-ace-likelihood-wrapper .sz-ace-likelihood-table .ant-input-number
    padding-left: 24px !important
    width: 100% !important

.sz-prob-placeholder,
.sz-mini-likelihood-stochastic
    @extend .fullcover
    cursor pointer
    font-size: 11px
    display: flex
    flex-direction: column
    padding 10%
</style>
